
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LargeIcon extends Vue {

@Prop({required: true})
icon!: string[]

}
