
import Table from '@/components/Table/Table.vue'
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'
import FButton from '@/components/Button/FButton.vue'
import Modal from '@/components/Modal/Modal.vue'
import LyricsModal from './../Shared/LyricsModal.vue';
import ChordsModal from './../Shared/ChordsModal.vue';
import SimplePagination from '@/components/Pagination/SimplePagination.vue';
import { Component, Vue } from 'vue-property-decorator'
import { CatalogSongsService } from '@/services/CatalogSongsService'
import { i18n } from '@/main';
import { DynamicTranslationService } from '@/services/DynamicTranslationService';
import { ProfileService } from '@/services/ProfileService';

@Component({
  metaInfo: () => ({
    title: DynamicTranslationService.searchAllCatalogsTitle
  }),
  components:{
    Table,
    SvgIcon,
    FButton,
    Modal,
    SimplePagination,
    LyricsModal,
    ChordsModal
  }
})
export default class CatalogSongResults extends Vue {
  store = CatalogSongsService;

  get featureToggleChords(){
    return ProfileService.profile?.featureToggleChords || false;
  }
  get columns() {
    const cols = [
      {
        key:'songTitle',
        label: i18n.t('R.LIT_SongTitle').toString(),
        sortable: true,
        selected: false,
        ascDir: true,
        apiParam: 'Title'
      },
      {
        key:'contributors',
        label: i18n.t('R.LIT_SongContributors').toString()
      },
      {
        key:'catalogs',
        label: i18n.t('R.LIT_Catalogs').toString()
      },
      {
        key: 'lyrics',
        label: i18n.t('R.LIT_Lyrics').toString(),
        sortable: true ,
        selected: false,
        ascDir: true,
        apiParam: 'Lyrics'
      }
    ];

    if (this.featureToggleChords){
      cols.push({
        key: 'chords',
        label: i18n.t('R.LIT_Chords').toString(),
        sortable: true ,
        selected: false,
        ascDir: true,
        apiParam: 'Chords'
      });
    }

    cols.push(
      {
        key: 'ccliSongId',
        label: i18n.t('R.LIT_SongId').toString(),
        sortable: true ,
        selected: false,
        ascDir: true,
        apiParam: 'SongId'
      });
    if (!this.store.query.sortBy){
      return cols;
    }
    if (this.store.query.sortBy === 'TitleAsc'){
      cols.find(c => c.key === 'songTitle')!.selected = true;
      cols.find(c => c.key === 'songTitle')!.ascDir = true;
    }else if (this.store.query.sortBy === 'TitleDesc'){
      cols.find(c => c.key === 'songTitle')!.selected = true;
      cols.find(c => c.key === 'songTitle')!.ascDir = false;
    }else if (this.store.query.sortBy === 'LyricsAsc'){
      cols.find(c => c.key === 'lyrics')!.selected = true;
      cols.find(c => c.key === 'lyrics')!.ascDir = true;
    }else if (this.store.query.sortBy === 'LyricsDesc'){
      cols.find(c => c.key === 'lyrics')!.selected = true;
      cols.find(c => c.key === 'lyrics')!.ascDir = false;
    }else if (this.store.query.sortBy === 'ChordsAsc'){
      cols.find(c => c.key === 'chords')!.selected = true;
      cols.find(c => c.key === 'chords')!.ascDir = true;
    }else if (this.store.query.sortBy === 'ChordsDesc'){
      cols.find(c => c.key === 'chords')!.selected = true;
      cols.find(c => c.key === 'chords')!.ascDir = false;
    }else if (this.store.query.sortBy === 'SongIdAsc'){
      cols.find(c => c.key === 'ccliSongId')!.selected = true;
      cols.find(c => c.key === 'ccliSongId')!.ascDir = true;
    }else if (this.store.query.sortBy === 'SongIdDesc'){
      cols.find(c => c.key === 'ccliSongId')!.selected = true;
      cols.find(c => c.key === 'ccliSongId')!.ascDir = false;
    }
    return cols;
  }

  songPath = `/intellectualproperty/catalogs/${this.store.catalogId}/`;

  get search() {
    return this.$route.query.s as string;
  }

  get numResults() {
    return this.store.numResults;
  }

  get page() {
    return this.store.query.page;
  }

  get numPerPage(){
    return this.store.query.numPerPage;
  }

  onNumPerPageChange(numPerPage: number){
    this.store.setNumPerPage(numPerPage);
  }

  goToPage(page: number){
    this.store.setPage(page);
  }

  onHeadingClick(key: string) {
    const col = this.columns.find(c => c.key === key)
    if (!col || !col.apiParam){
      return;
    }
    let sortBy = col.apiParam;
    if (col.selected){
      col.ascDir = !col.ascDir;
    }else{
      this.columns.filter(c => c.sortable).forEach(c => c.selected = false)
      col.selected = true;
      col.ascDir = true;
    }

    sortBy += col.ascDir ? 'Asc' : 'Desc';
    this.store.setSortBy(sortBy)
  }

  openLyricsModal(songId: string, songTitle: string){
    (this.$refs.lyricsModal as LyricsModal).init(songId, songTitle);
  }

  openChordsModal(songId: string, songTitle: string, authors: string){
    (this.$refs.chordsModal as ChordsModal).init(songId, songTitle, authors);
  }
}
